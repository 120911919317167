import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import tw from "twin.macro";

export const DeliveryInfoWrapper = styled.div`
  ${tw`px-4 border-0 border-b-8 border-solid border-gray-100`}
  width: 100%;
  background-color: transparent;
`;

export const DeliveringToWrapper = styled.div`
  ${tw`flex py-3 items-center justify-between`}
  width: 100%;
  background-color: transparent;
  h4 {
    ${tw`flex items-center text-sm pb-3`}
  }
  p {
    ${tw`flex items-center text-sm`}
  }
  svg {
    ${tw`mr-2`}
  }
`;

export const DeliveryTypeWrapper = styled.div`
  ${tw`flex pt-1 pb-3 mb-4 items-center justify-between`}
  width: 100%;
  background-color: transparent;
  p {
    ${tw`flex items-center text-xs`}
  }
  svg {
    ${tw`mr-2`}
  }
`;
