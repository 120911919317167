import React from 'react';
import { SearchIconSmall, Map, Clock, Basket } from '../AllSvgIcon.js';
import {
  DeliveryInfoWrapper,
  DeliveringToWrapper,
  DeliveryTypeWrapper
} from './CustomNote.style';
import { useStores } from 'hooks/useStores.js';
import DeliverySelection from 'components/DeliverySelection/DeliverySelection.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeliverySelectionSmall from 'components/DeliverySelection/DeliverySelectionSmall.js';
import { withTranslation } from 'react-i18next';
function CustomNote({ custom_note, i18n, ...props }) {
  const { cartStore } = useStores();
  const { currentRestaurant, currentOutlet, currentArea } = cartStore;

  if (
    !custom_note ||
    !custom_note[i18n.language]
  )
    return null;

  return (
    <div className="flex items-center m-4">
      <div className="bg-gray-200 border border-gray p-3 mb-1 rounded flex w-full text-xs">
        {custom_note[i18n.language]}
      </div>
    </div>
  );
}

export default withTranslation('common')(CustomNote);
