import React from 'react';
import { SearchIconSmall } from '../AllSvgIcon.js';
import {
  DeliverySelectionWrapper,
  DeliveryType,
  DeliveryTypeSmall
} from './DeliverySelection.style';
import { useStores } from 'hooks/useStores.js';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { withTranslation } from 'i18n.js';
const DeliverySelectionSmall = observer(({ t, i18n }) => {
  const { cartStore } = useStores();
  const router = useRouter();
  const { restaurant, store, orderType, channel } = router.query;

  React.useEffect(() => {
    if (
      orderType &&
      cartStore.orderType !== orderType &&
      ['pickup', 'delivery'].includes(orderType)
    ) {
      cartStore.setOrderType(orderType);
    }
  }, [orderType]);

  const changeOrderType = (type) => {
    cartStore.setOrderType(type);
    if (store) {
      if (cartStore.currentRestaurant.domain) router.replace(`/${type}`);
      else
        router.replace(`/[restaurant]/[orderType]`, `/${restaurant}/${type}`);
    }
  };
  return (
    <DeliverySelectionWrapper>
      {cartStore.currentRestaurant &&
      cartStore.currentRestaurant.has_delivery ? (
        <DeliveryTypeSmall
          className={orderType == 'delivery' ? 'active' : ''}
          onClick={() => changeOrderType('delivery')}
          lang={i18n.language}
        >
          {t('delivery')}
        </DeliveryTypeSmall>
      ) : null}
      {cartStore.currentRestaurant && cartStore.currentRestaurant.has_pickup ? (
        <DeliveryTypeSmall
          className={orderType == 'pickup' ? 'active' : ''}
          onClick={() => changeOrderType('pickup')}
          lang={i18n.language}
        >
          {t('pickup')}
        </DeliveryTypeSmall>
      ) : null}
    </DeliverySelectionWrapper>
  );
});

export default withTranslation('common')(DeliverySelectionSmall);
